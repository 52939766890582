import { useEffect, useState } from "react";

import { DataTable } from "primereact/datatable";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthenticatedRequest from "../../utils/TokenRefreshUtils";
import { Column } from "primereact/column";
import { formatCurrency, formatDate } from "../../utils/CommonUtils";
import { Button } from "primereact/button";

const SaleDetails = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const authenticatedRequest = useAuthenticatedRequest();

    const [actDetails, setActDetails] = useState(null);
    const [actProducts, setActProducts] = useState([]);
    const [productSums, setProductSums] = useState([]);
    const [payments, setPayments] = useState([]);

    useEffect(() => {

        const getActPayments = async (id) => {

            const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/get_payments_by_act/?act_id=${id}`;
            const options = { method: "GET", headers: { 'Content-Type': 'application/json' } };
            
            const response = await authenticatedRequest(url, options);
            if(response.status === 200){
                const parsedResponse = await response.json();
                setPayments(parsedResponse.results);
            }

            console.log("payments response: ", response);
        }

        const getClients = async (id) => {
            const url = `${process.env.REACT_APP_BACKEND_URL}enums/clients/`;
            const options = { method: "GET", headers: { 'Content-Type': 'application/json' } };
            
            const response = await authenticatedRequest(url, options);
            if(response.status === 200){
                const parsedResponse = await response.json();
                const client = parsedResponse.find(cl => cl.id = id);
                return client.name;
            }
            else{
                return null;
            }
        }

        const fetchData = async () => {
            const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/get_details/${location.state.id}/`;
            const options = { method: "GET", headers: { 'Content-Type': 'application/json' } };
            
            const response = await authenticatedRequest(url, options);

            if(response.status === 200){
                const parsedResponse = await response.json();

                if(parsedResponse.cagent !== null){
                    const clientName = await getClients(parsedResponse.cagent);
                    parsedResponse.cagent = clientName;
                } 

                setActDetails(parsedResponse);
    
                let productList = [];
                if (parsedResponse.identified_products.length > 0) {
                    productList = [...productList, ...parsedResponse.identified_products];
                }
                if (parsedResponse.unidentified_products.length > 0) {
                    productList = [...productList, ...parsedResponse.unidentified_products];
                }
    
                let sums = [];
                productList.forEach(product => {
                    const index = sums.findIndex(x => x?.currency === product.currency);
                    if(index !== -1){
                        sums[index].amount += product.price;
                    }
                    else
                    {
                        const sum = {
                            "currency": product.currency,
                            "amount": product.price
                        }
    
                        sums.push(sum);
                    }
                })
    
                setProductSums(sums);
                setActProducts(productList);

                getActPayments(parsedResponse.id);
            }
        };

        fetchData();
    }, [authenticatedRequest, location.state.id]);

    const hasDiscount = () => {
        if(actDetails?.discount){
            if(actDetails.discount?.length === 0){
                return false;
            }
            else{
                actDetails.discount?.forEach(discount => {
                    if(discount?.amount > 0){
                        return true;
                    }
                });
            }
        }

        return false;
    }

    const itemTemplate = (item) => {
        console.log(item);
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                <div className="flex-1 flex flex-column gap-2">
                    <span className="font-bold"> {item.product}</span>
                    <div className="flex align-items-center gap-2">
                        <i className="pi pi-tag text-sm"></i>
                        <span>{item.identifier !== undefined ? item.identifier : "Кількість: " + item.quantity}</span>
                    </div>
                </div>
                <span className="font-bold text-900">{formatCurrency(item.price, item.currency)}</span>
            </div>
        );
    };

    console.log(actDetails);

    return (<div className="grid card">
        <div className="col-12 md:col-6 flex flex-column">
            <DataTable value={actProducts} header="Товари та послуги">
                <Column field="id" header="Назва" headerStyle={{ display: 'none' }} body={(rowData) => itemTemplate(rowData)} />
            </DataTable>
        </div>
        <div className="col-12 md:col-6">
            <div className="surface-section">
                <div className="text-center">
                    <div className="font-medium text-xl text-900 mb-3 mt-3">Загальні дані акту</div>
                </div>
                <ul className="list-none p-0 m-0">
                    <li key="cagent" className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                        <div className="text-500 w-6 md:w-2 font-medium">Клієнт</div>
                        <div className="w-6 font-medium flex flex-column text-right">
                            <span className='mr-3 mt-2 text-800 text-large'>{actDetails?.cagent === null ? "Клієнта не зазначено" : actDetails?.cagent}</span>
                        </div>
                    </li>
                    <li key="manager" className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                        <div className="text-500 font-medium">Зареєстровано</div>
                        <div className="w-6 font-medium flex flex-column text-right">
                            <span className='mr-3 mt-2 text-800 text-large'>{`${actDetails?.created_by}` }</span>
                            <span className="mr-3 text-800 text-large">{`(${formatDate(actDetails?.created_at)})`}</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="surface-section">
                <div className="text-center">
                    <div className="font-medium text-xl text-900 mb-3 mt-3">Нараховано</div>
                </div>
                <ul className="list-none p-0 m-0">
                    {productSums.map((payment, index) => (
                        <li key={index} className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                            <div className="text-500 w-6 md:w-2 font-medium">{payment.currency}</div>
                            <div className="w-6 font-medium flex flex-column text-right">
                                <span className='mr-3 mt-2 text-800 text-large'>{formatCurrency(payment.amount, payment.currency)}</span>
                            </div>
                        </li>
                    ))}
                    <li key="totalUAH" className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                        <div className="text-500 font-medium">Еквівалент в гривні</div>
                        <div className="w-6 font-medium flex flex-column text-right">
                            <span className='mr-3 mt-2 text-800 text-large'>{formatCurrency(actDetails?.amountUAH, "UAH")}</span>
                        </div>
                    </li>
                </ul>
            </div>
            {hasDiscount() && (
                <div className="surface-section">
                    <div className="text-center">
                        <div className="font-medium text-xl text-900  mb-3">Знижка</div>
                    </div>
                    <ul className="list-none p-0 m-0">
                        {actDetails.discount.map((discount, index) => (discount.amount > 0 &&
                            <li key={`discount${index}`} className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                                <div className="text-500 w-6 md:w-2 font-medium">{discount.currency}</div>
                                <div className="w-6 font-medium flex flex-column text-right">
                                    <span className='mr-3 mt-2 text-800 text-large'>{formatCurrency(discount.amount, discount.currency)}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div className="surface-section">
                <div className="text-center">
                    <div className="font-medium text-xl text-900 mb-3 mt-2">До оплати</div>
                </div>
                <ul className="list-none p-0 m-0">
                    {actDetails?.count_amount.map((payment, index) => (
                        <li key={index} className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                            <div className="text-500 w-6 md:w-2 font-medium">{payment.currency}</div>
                            <div className="w-6 font-medium flex flex-column text-right">
                                <span className='mr-3 mt-2 text-800 text-large'>{formatCurrency(payment.amount, payment.currency)}</span>
                            </div>
                        </li>
                    ))}
                    <li key="totalUAH" className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                        <div className="text-500 font-medium">Еквівалент в гривні</div>
                        <div className="w-6 font-medium flex flex-column text-right">
                            <span className='mr-3 mt-2 text-800 text-large'>{formatCurrency(actDetails?.amountUAH, "UAH")}</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="surface-section">
                <div className="text-center">
                    <div className="font-medium text-xl text-900 mb-3 mt-2">Оплата</div>
                </div>
                <ul className="list-none p-0 m-0">
                    {payments?.map((payment, index) => (
                        <li key={index} className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                            <div className="text-500 w-6 md:w-2 font-medium">{payment.currency}</div>
                            <div className="w-6 font-medium flex flex-column text-right">
                                <span className='mr-3 mt-2 text-800 text-large'>{formatCurrency(payment.distribution_sum, payment.currency)}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="flex flex-column buttonsContainer">
            <Button className="mt-1 justify-content-center editButton font-bold" onClick={() => {navigate("/salehistory")}}>Назад</Button>
            <Button className="mt-1 justify-content-center editButton font-bold" onClick={console.log("Smth")}>Оформити повернення</Button>
            </div>
        </div>
    </div>)
}

export default SaleDetails;