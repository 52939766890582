// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainPageButton{
    width:100%
}

.mainPageButtonDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.mainPageSideBar{
    width:30%
}

@media (max-width: 1024px) {
    .mainPageSideBar {
        width: 50%; /* Full width on tablets and smaller screens */
    }
}

@media (max-width: 768px) {
    .mainPageSideBar{
        width:80%
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Layout.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI;AACJ;;AAEA;IACI;QACI,UAAU,EAAE,8CAA8C;IAC9D;AACJ;;AAEA;IACI;QACI;IACJ;AACJ","sourcesContent":[".mainPageButton{\n    width:100%\n}\n\n.mainPageButtonDiv{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 2rem;\n}\n\n.mainPageSideBar{\n    width:30%\n}\n\n@media (max-width: 1024px) {\n    .mainPageSideBar {\n        width: 50%; /* Full width on tablets and smaller screens */\n    }\n}\n\n@media (max-width: 768px) {\n    .mainPageSideBar{\n        width:80%\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
