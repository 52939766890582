import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import useAuthenticatedRequest from '../../utils/TokenRefreshUtils';
import useValidateShift from '../../utils/ShiftUtils';
import { formatCurrency, formatShortDate } from '../../utils/CommonUtils';
import Cookies from 'js-cookie';
import { useZxing } from "react-zxing";

import { addLocale } from 'primereact/api';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from "primereact/floatlabel";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { RadioButton } from 'primereact/radiobutton';
import { Sidebar } from 'primereact/sidebar';
import { Stepper } from 'primereact/stepper';
import { TabPanel } from 'primereact/tabview';
import { TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { StepperPanel } from 'primereact/stepperpanel';

import "./NewSale.css";

const NewSale = () => {
    const stepperRef = useRef(null);
    const toast = useRef(null);
    const authenticatedRequest = useAuthenticatedRequest();
    const validateShift = useValidateShift();
    const navigate = useNavigate();
    const location = useLocation();
    const currencies = ['UAH', 'USD', 'EUR'];
    const paymentMethods = ["Готівка", "Термінал", "IBAN"];

    const [products, setProducts] = useState([]);
    const [services, setServices] = useState([]);
    const [target, setTarget] = useState([]);
    const [sourceFilter, setSourceFilter] = useState('');
    const [targetFilter, setTargetFilter] = useState('');
    const [clientFilter, setClientFilter] = useState('');
    const [visible, setVisible] = useState(false);
    const [clients, setClients] = useState([]);
    const [sideBarType, setSideBarType] = useState(null);
    const [clientSideBarVisible, setClientSideBarVisible] = useState(false);
    const [currencyRates, setCurrencyRates] = useState(null);
    const [totalSumUAH, setTotalSumUAH] = useState(0);
    const [unDividedSum, setUnDividedSum] = useState(0);
    const [unDiscountedPayments, setUndiscountedPayments] = useState([]);
    const [reloadClients, setReloadClients] = useState(true);
    const [devices, setDevices] = useState([]);
    const [deviceId, setDeviceId] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isScanningDisabled, setIsScanningDisabled] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "id",
        sortOrder: 1,
    });    

    addLocale('ua', {
        firstDayOfWeek: 1,
        dayNames: ['неділя','понеділок', 'вівторок', 'середа', 'четвер', 'пятниця', 'субота'],
        dayNamesShort: ['нд','пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        dayNamesMin: ['нд','пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        monthNames: ['січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень'],
        monthNamesShort: ['січ', 'лют', 'бер', 'кві', 'тра', 'чер', 'лип', 'сер', 'вер', 'жов', 'лис', 'гру'],
        today: 'Сьогодні',
        clear: 'Очистити',
    });


    useEffect(() => {

        const fetchProducts = async () => {
            const url = `${process.env.REACT_APP_BACKEND_URL}remainings/get_remainings_cash_app/`;
            const options = {
                method: "GET",
                headers: { 'Content-Type': 'application/json' }
            };
            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();

            if (location.state !== null) {
                let products = [];
                location.state.sale.products.forEach(product => {
                    let productToAdd = {};
                    if (product.service_id === undefined) {
                        if (product.identifier !== undefined) {
                            productToAdd.id = product.remaining_id;
                            productToAdd.product_name = product.product;
                            productToAdd.product_id = product.product_id;
                            productToAdd.total_quantity = 1;
                            productToAdd.identifier = product.identifier;
                            productToAdd.booked = 1;
                            productToAdd.sale_price = product.price;
                            productToAdd.sale_price_currency = product.currency;
                            productToAdd.available_quantity = 0;
                            productToAdd.quantity = 1;
                        }
                        else {
                            productToAdd = parsedResponse.find(p => p.id === product.remaining_id);
                            productToAdd.quantity = product.quantity;
                        }
                    }
                    else {
                        productToAdd.service_id = product.service_id;
                        productToAdd.id = "s" + product.service_id;
                        productToAdd.product_name = product.service;
                        productToAdd.product_group = product.service_group;
                        productToAdd.sale_price = +product.price;
                        productToAdd.sale_price_currency = product.currency;
                    }

                    products = [
                        ...products,
                        productToAdd
                    ]
                });

                setTarget(products);
            }

            parsedResponse.map(product => {
                product.available_quantity = product.total_quantity - product.booked;
                return product;
            });

            let filteredProducts = parsedResponse.filter(item => item.total_quantity !== 0);
            filteredProducts = filteredProducts.filter(item => +item.total_quantity !== item.booked);
            setProducts(filteredProducts);
        }

        const fetchServices = async () => {
            const url = `${process.env.REACT_APP_BACKEND_URL}enums/services/`;
            const options = {
                method: "GET",
                headers: { 'Content-Type': 'application/json' }
            };
            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();

            parsedResponse.map(service => {
                service.service_id = service.id;
                service.id = "s" + service.id;
                service.product_name = service.name;
                service.product_group = service.service_group;
                service.sale_price = +service.price;
                service.sale_price_currency = service.currency;
                return service;
            });
            setServices(parsedResponse);
        }

        const fetchData = async () => {
            await fetchProducts();
            await fetchServices();
        };

        fetchData();

    }, [authenticatedRequest, location.state]);

    useEffect(() => {
        const fetchClients = async () => {
            const url = `${process.env.REACT_APP_BACKEND_URL}cagents/get_clients/?page=${lazyState.page}&page_size=${lazyState.rows}&ordering=${("-" + lazyState.sortField)}`;
            const options = { 
                method: "GET", 
                headers: { 'Content-Type': 'application/json' } 
            };
            const response = await authenticatedRequest(url, options);
            if(response.status === 200){
                const parsedResponse = await response.json();
                console.log(parsedResponse);
                setClients(parsedResponse.results);
                setTotalRecords(parsedResponse.count);
                setLoading(false);
            }
            else{
                toast.current.show({ severity: "error", summary: "Помилка завантаження", detail: "Помилка отримання списку клінєтів. Код помилки: " + response.status, life: 2000 })
                setLoading(false);
            }

        };
        fetchClients();
    }, [authenticatedRequest, reloadClients, lazyState]);

    const onPage = (event) => {
        event.page = event.page +1;
        setlazyState(event);
        console.log(event);
    };

    const formatNewSaleRequest = (values) => {

        let newSaleRequest = {};
        newSaleRequest.cagent = values.client != null ? values.client.id : null;
        newSaleRequest.division = 1;
        newSaleRequest.act_date = formatShortDate(new Date());
        newSaleRequest.pay_due_date = formatShortDate(new Date());
        newSaleRequest.act_number_autogenerate = true;
        newSaleRequest.unidentified_products = [];
        newSaleRequest.identified_products = [];
        newSaleRequest.services = [];
        newSaleRequest.shift_id = Cookies.get("ShiftId");
        newSaleRequest.discount = values.discount;

        if (formik.values.isEdit) {
            newSaleRequest.act_date = formik.values.actDate;
            newSaleRequest.act_number = formik.values.actNumber;
            newSaleRequest.pay_due_date = formik.values.payDueDate;
        }

        values.products.forEach(product => {
            if (product.identifier !== undefined) {
                let identifiedProduct = {};
                identifiedProduct.remaining_id = product.id;
                identifiedProduct.price = product.sale_price;
                identifiedProduct.priceUAH = product.sale_price * currencyRates[product.sale_price_currency];
                identifiedProduct.currency = product.sale_price_currency;

                newSaleRequest.identified_products = [
                    ...newSaleRequest.identified_products,
                    identifiedProduct
                ]
            }
            else if (product.service_id !== undefined) {
                let service = {};
                service.service_id = product.service_id;
                service.price = product.sale_price;
                service.priceUAH = product.sale_price * currencyRates[product.sale_price_currency];
                service.currency = product.sale_price_currency;

                newSaleRequest.services = [
                    ...newSaleRequest.services,
                    service
                ]
            }
            else {
                let unIdentifiedProduct = {};
                unIdentifiedProduct.remaining_id = product.id;
                unIdentifiedProduct.quantity = product.quantity;
                unIdentifiedProduct.price = product.sale_price;
                unIdentifiedProduct.priceUAH = product.sale_price * currencyRates[product.sale_price_currency];
                unIdentifiedProduct.currency = product.sale_price_currency;

                newSaleRequest.unidentified_products = [
                    ...newSaleRequest.unidentified_products,
                    unIdentifiedProduct
                ]
            }
        });

        return newSaleRequest;
    }

    useEffect(() => {
        const fetchCurrencies = async () => {
            const url = `${process.env.REACT_APP_BACKEND_URL}currency/get_currencies_list/`;
            const options = { method: "GET", headers: { 'Content-Type': 'application/json' } };
            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();

            const receivedRates = parsedResponse.reduce((acc, curr) => {
                acc[curr.currency_short] = curr.currency_rate;
                return acc;
            }, {});

            setCurrencyRates(receivedRates);
        };
        fetchCurrencies();

    }, [authenticatedRequest]);

    const { ref } = useZxing({
        onDecodeResult: async (newScan) => {

            if(isScanningDisabled){
                return;
            }

            setIsScanningDisabled(true); // Disable further scans
            
            let product = await GetProductByBarCode(newScan);
            console.log(product);
            if (product !== null) {

                if (product.identifier !== undefined) 
                {
                    if(target.find(x => x.identifier === product.identifier)){
                        toast.current.show({ severity: "error", summary: "Помилка додавання", detail: "Товар з штрих-кодом " + newScan + " вже додано", life: 2000 })
                        setIsScanningDisabled(false);
                        return;
                    }
                    product.total_quantity = 1;
                    product.quantity = 1;
                }
                else {
                    product.quantity = 1;
                }
                console.log(product);
                moveToTargetHandler(product);
            }
            else {
                toast.current.show({ severity: "error", summary: "Помилка пошуку", detail: "Товар з штрих-кодом " + newScan + " не знайдено", life: 2000 })
                setIsScanningDisabled(false);
            }
        },
        deviceId
    });

    useEffect(() => {

        const runScanner = async () => {
            try {
                const availableDevices = await navigator.mediaDevices.enumerateDevices();
                const availableVideoDevices = availableDevices.filter(device => device.kind === 'videoinput');
                if (availableVideoDevices.length === 0) {
                    console.log('No cameras found');
                } else {
                    const formattedDevices = availableVideoDevices.map(device => ({
                        id: device.deviceId,
                        label: device.label || `Camera ${device.deviceId}`
                    }));
                    console.log(formattedDevices);
                    setDevices(formattedDevices);
                }
            } catch (e) {
                console.log('Failed to find cameras. This could be a permissions problem', e);
            }
        }

        runScanner();

    }, []);

    //#region Calls to backEnd
    const createSale = async (saleRequest) => {

        const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/create/`;
        const options = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(saleRequest)
        };
        const saleResponse = await authenticatedRequest(url, options);

        if(saleResponse.status === 201){
            const parsedResponse = await saleResponse.json();
            return parsedResponse.id;
        }

        return null;
    }

    const editSale = async (saleRequest) => {

        const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/update/${formik.values.saleId}/`;
        const options = {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(saleRequest)
        };
        const saleResponse = await authenticatedRequest(url, options);

        if(saleResponse.status === 200){
            return true;
        }

        return null;
    }

    const finalizeSale = async (id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/make_finalized/${id}/`;
        const options = {
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        };
        const finalizeResponse = await authenticatedRequest(url, options);

        return finalizeResponse;
    }

    const createPayment = async (paymentRequest) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/create/`;
        const options = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(paymentRequest)
        };
        const paymentResponse = await authenticatedRequest(url, options);
        if(paymentResponse.status === 201){
            return true;
        }

        return null;
    }

    const GetProductByBarCode = async (barcode) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}remainings/get_remainings/?barcode=${barcode}`;
        const options = {
            method: "GET",
            headers: { 'Content-Type': 'application/json' },
        };
        const productResponse = await authenticatedRequest(url, options);
        console.log(productResponse);
        if(productResponse.status === 200){
            const parsedResponse = await productResponse.json();
            console.log(parsedResponse);

            if(parsedResponse.results.length > 0)
            {
                return parsedResponse.results[0];
            }
        }

        return null;
    }

    //#endregion

    const formik = useFormik({
        initialValues: {
            products: [],
            discount: [],
            basePayments: [],
            payments: [],
            client: null,
            isEdit: false
        },

        validate: values => {
            const errors = {};

            values.payments.forEach((payment, index) => {
                if (payment.paymentMethod === null || payment.paymentMethod === '') {
                    if (!errors.payments) errors.payments = [];
                    errors.payments[index] = { paymentMethod: 'Вкажіть спосіб оплати' };
                }
            });

            console.log(values.payments)
            console.log(errors);

            return errors;
        },
        onSubmit: (values) => {

            const performSale = async () => {

                const isShiftValid = await validateShift();

                if (!isShiftValid) {
                    const toastMessage = { severity: "error", summary: "Помилка зміни", detail: "Зміна закрита", life: 3000 };
                    navigate('/shift', { state: { toast: toastMessage } });
                }
                else {
                    const newSaleRequest = formatNewSaleRequest(values);

                    const saleResponse = values.isEdit ? await editSale(newSaleRequest) : await createSale(newSaleRequest);

                    if (saleResponse !== null) {

                        const saleId = values.isEdit ? values.saleId : saleResponse;

                        const finalizeResponse = await finalizeSale(saleId);

                        if (finalizeResponse.status === 200) {

                            let paymentRequest = [];

                            values.payments.forEach(payment => {

                                let paymentType = "CASH";
                                if (payment.paymentMethod === "Термінал") {
                                    paymentType = "CARD";
                                }
                                else if (payment.paymentMethod === "IBAN") {
                                    paymentType = "IBAN";
                                }

                                const singlePaymentRequest = {
                                    act_id: saleId,
                                    date: formatShortDate(Date.now()),
                                    sum: payment.sum,
                                    currency: payment.currency,
                                    type: paymentType,
                                    method: "INCOME",
                                    cagent_id: values.client != null ? values.client.id : null,
                                    shift_id: Cookies.get("ShiftId")
                                }

                                paymentRequest.push(singlePaymentRequest);
                            });

                            const paymentResponse = createPayment(paymentRequest);

                            if (paymentResponse !== null) {
                                const toastMessage = { severity: "success", summary: 'Оплата', detail: 'Оплата пройшла успішно', life: 2000 };
                                navigate('/shift', { state: { toast: toastMessage } });
                            }
                            else {
                                toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Помилка створення платежу, код помилки:" + finalizeResponse.status, life: 2000 });
                            }
                        }
                        else {
                            toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Помилка фіналізації акту, код помилки:" + finalizeResponse.status, life: 3000 });
                        }
                    }
                    else {
                        toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Помилка створення акту", life: 3000 });
                    }
                }
            }


            performSale();
        }
    });

    const newUserFormik = useFormik({
        initialValues : {
            type: "INDIVIDUAL",
            division: 1,
            phone: "",
            email: "",
            is_partner: false,
            is_client: true,
            country: "Україна",
            region: null,
            city: "",
            street: "",
            building_number: "",
            apartment_number: "",
            notes: "",
            individual_cagent_data: {
                first_name: "",
                last_name: "",
                surname: "",
                date_of_birth: null
            },
            legal_cagent_data: {
                long_name: "",
                short_name: "",
                representative: "",
                edrpou: ""
            },
            cagent_financial_data: []
        },
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.type === "INDIVIDUAL") {

                if (values.individual_cagent_data.last_name === null || values.individual_cagent_data.last_name === "") {
                    errors.indLastName = "Прізвище контрагента не повинне бути пустим";
                }

                if (values.individual_cagent_data.first_name === null || values.individual_cagent_data.first_name === "") {
                    errors.indFirstName = "Імя контрагента не повинне бути пустим";
                }

            }
            else {

                if (values.legal_cagent_data.long_name === null || values.legal_cagent_data.long_name === "") {
                    errors.legalLongName = "Повна назва організації не повинна бути пустою";
                }

                if (values.legal_cagent_data.short_name === null || values.legal_cagent_data.short_name === "") {
                    errors.legalShortName = "Коротка назва організації не повинна бути пустою";
                }
                if (values.legal_cagent_data.edrpou === null || values.legal_cagent_data.edrpou === "") {
                    errors.legalEdrpou = "ЄДРПОУ не повинен бути пустим";
                }
                else if (isNaN(Number(values.legal_cagent_data.edrpou))) {
                    errors.egalEdrpou = 'ЄДРПОУ повинен бути числом';
                }
            }

            return errors;
        },
        onSubmit: values => {
            values.country = "UKRAINE";
            values.region = "LVIV_REGION"

            if (values.individual_cagent_data?.date_of_birth) {
                values.individual_cagent_data.date_of_birth = formatShortDate(values.individual_cagent_data.date_of_birth);
            }

            const { type, individual_cagent_data, legal_cagent_data, ...rest } = values;

            values = {
                type,
                ...rest,
                ...(type === "INDIVIDUAL" ? { individual_cagent_data } : { legal_cagent_data })
            };

            console.log(values);

            const addCounterAgentHandler = async event => {

                const url = process.env.REACT_APP_BACKEND_URL + "cagents/create/";
                const method = "POST";

                const options = {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                };

                const response = await authenticatedRequest(url, options);
                console.log(response);

                setReloadClients(!reloadClients);
                newUserFormik.resetForm();
                setClientSideBarVisible(false);
            }

            addCounterAgentHandler();
        }
    });

    const saveDraft = async () => {

        const isShiftValid = await validateShift();

        if (!isShiftValid) {
            const toastMessage = { severity: "error", summary: "Помилка зміни", detail: "Зміна закрита", life: 3000 };
            navigate('/shift', { state: { toast: toastMessage } });
        } else {

            const newSaleRequest = formatNewSaleRequest(formik.values);
            const isEdit = formik.values.isEdit;
            const saleResponse = isEdit ? await editSale(newSaleRequest) : await createSale(newSaleRequest);
            console.log(newSaleRequest);
            console.log(saleResponse);
            const id = isEdit ? formik.values.saleId : saleResponse;

            if (saleResponse !== null) {
                const toastMessage = isEdit ?
                    { severity: "success", summary: "Акт успішно відрудаговано", detail: "Драфт акту " + id + " успішно відредаговано", life: 2000 } :
                    { severity: "success", summary: "Акт успішно збережено", detail: "Драфт акту " + id + " успішно збережено", life: 2000 };
                navigate('/shift', { state: { toast: toastMessage } });
            }
            else {
                toast.current.show({ severity: "error", summary: "Помилка збереження акту", detail: "Помилка збереження акту", life: 2000 });
            }
        }
    }

    useEffect(() => {

        let discountAmount = 0;
        let convertedAmount = 0;

        if (formik.values.discount?.length !== 0) {
            formik.values.discount.forEach(discount => {
                discountAmount += discount.amount * currencyRates[discount.currency];
            });
        }

        if (formik.values.payments.length !== 0) {
            formik.values.payments.forEach(payment => {
                convertedAmount += payment.sum * currencyRates[payment.currency];
            });
        }

        setUnDividedSum(totalSumUAH - convertedAmount - discountAmount);

    }, [formik.values.payments, formik.values.discount, totalSumUAH, currencyRates]);

    const quantityFormik = useFormik({
        initialValues: {
            product: {},
            quantity: 1,
            isAdd: true
        },
        enableReinitialize: true,
        onSubmit: (values) => {

            if (values.isAdd) {

                const index = target.findIndex(item => item.id === values.product.id);

                if (index >= 0) {
                    let newTarget = target;

                    if (newTarget[index].available_quantity === values.quantity) {
                        newTarget[index].quantity += +values.quantity;
                        newTarget[index].available_quantity -= +values.quantity;
                        const newSource = products.filter(item => item.id !== newTarget[index].id);

                        setProducts(newSource);
                    }
                    else {
                        newTarget[index].quantity += +values.quantity;
                        newTarget[index].available_quantity -= +values.quantity;
                    }

                    setTarget(newTarget);
                }
                else {
                    if (values.product.available_quantity === +values.quantity) {
                        const newSource = products.filter(item => item.id !== values.product.id);

                        setProducts(newSource);
                    }
                    const newProduct = values.product;
                    newProduct.quantity = +values.quantity;
                    newProduct.available_quantity = values.product.available_quantity - +values.quantity;

                    const newTarget = [
                        ...target,
                        newProduct
                    ];

                    setTarget(newTarget);
                }
            }
            else {
                const index = products.findIndex(item => item.id === values.product.id);

                if (index >= 0) {
                    if (values.product.quantity === +values.quantity) {
                        const newTarget = target.filter(item => item.id !== values.product.id);

                        setTarget(newTarget);
                    }

                    let newSource = products;
                    newSource[index].quantity -= +values.quantity;
                    newSource[index].available_quantity += +values.quantity;

                    setProducts(newSource);
                }
                else {

                    if (values.product.quantity === +values.quantity) {
                        const newTarget = target.filter(item => item.id !== values.product.id);

                        setTarget(newTarget);
                    }

                    const newProduct = values.product;
                    newProduct.available_quantity += +values.quantity;
                    newProduct.quantity -= +values.quantity;

                    const newSource = [
                        ...products,
                        newProduct
                    ]

                    setProducts(newSource);
                }
            }

            setVisible(false);
            quantityFormik.resetForm();
        }
    })

    const onSelectionChange = (e) => {
        formik.setFieldValue("client", e.value || null);
    };

    const addNewClient = () => {
        setClientSideBarVisible(true);
    }

    const clearClient = () => {
        formik.setFieldValue("client", null);
    };

    const handleCurrencyChange = (index, value) => {
        const discount = [...formik.values.discount];
        discount[index].currency = value;
        formik.setFieldValue('discount', discount);
    };

    const handleAmountChange = (index, value) => {
        const discount = [...formik.values.discount];
        discount[index].amount = value;
        formik.setFieldValue('discount', discount);
    };

    const handlePaymentsAmountChange = (index, newAmount) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].sum = newAmount;
        formik.setFieldValue('payments', newPayments);
    };

    const handlePaymentsCurrencyChange = (index, newCurrency) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].currency = newCurrency;
        formik.setFieldValue('payments', newPayments);
    };

    const handlePaymentMethodChange = (index, value) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].paymentMethod = value;
        formik.setFieldValue('payments', newPayments);
    };

    const deletePayment = (index) => {
        const newPayments = [...formik.values.payments];
        newPayments.splice(index, 1);
        formik.setFieldValue('payments', newPayments);
    };

    const addPaymentToFormik = () => {
        const newPayment = [
            ...formik.values.payments,
            { currency: "UAH", sum: 0.00, paymentMethod: null }
        ];
        formik.setFieldValue('payments', newPayment);
    };

    const existsDiscount = () => {
        let showDiscounts = false;

        formik.values.discount.forEach(discount => {
            if(discount.amount > 0){
                showDiscounts = true;
            }
        });

        return showDiscounts;
    }

    const showPaymentSideBar = () => {

        setUndiscountedPayments(formik.values.payments);
        console.log(formik.values.payments);

        if(existsDiscount()){

            const existingDiscounts = formik.values.discount;
            const existingCurrencyMap = new Map(existingDiscounts.map(discount => [discount.currency, discount.amount]));
            
            const paymentsWithDiscount = [];
            formik.values.payments.forEach(payment => {
                const currency = payment.currency;
                const amountFromExisting = existingCurrencyMap.get(currency) || 0;
    
                paymentsWithDiscount.push({
                    currency: currency,
                    sum: payment.sum - +amountFromExisting
                });
            });

            formik.setFieldValue("payments", paymentsWithDiscount);

        }

        sidebarHandler("payment");
    }

    const renderSourceHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText placeholder="Пошук товару" value={sourceFilter} onChange={(e) => setSourceFilter(e.target.value)} />
                </IconField>
            </div>
        );
    };

    const renderTargetHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText placeholder="Пошук товару" value={targetFilter} onChange={(e) => setTargetFilter(e.target.value)} />
                </IconField>
            </div>
        );
    };

    const handleTabChange = (data) => {
        if (activeIndex === 2){
            setDeviceId(null);
        }
        setActiveIndex(data.index);
    }

    const sourceHeader = renderSourceHeader();

    const targetHeader = renderTargetHeader();

    const moveToTargetHandler = (product) => {

        if (product.total_quantity && product.total_quantity > 1) {
            quantityFormik.setFieldValue('product', product);
            sidebarHandler("quantity");
        }
        else if (product.total_quantity === 1) {
            product.quantity = 1;
            const newTarget = [
                ...target,
                product
            ]

            const newSource = products.filter(item => item.id !== product.id);

            setTarget(newTarget);
            setProducts(newSource);
        }
        else {
            const newTarget = [
                ...target,
                product
            ]

            setTarget(newTarget);
        };
        if(activeIndex === 2){
            setDeviceId(null);
            setActiveIndex(0);
        }
        setIsScanningDisabled(false);
    }

    const moveToSourceHandler = (product) => {

        if (product.total_quantity && product.quantity > 1) {
            quantityFormik.setFieldValue('product', product);
            quantityFormik.setFieldValue('isAdd', false);
            sidebarHandler("quantity");
        }
        else if (product.quantity === 1) {

            const index = products.findIndex(item => item.id === product.id);
            let newSource = [];

            if (index >= 0) {
                newSource = products;
                newSource[index].available_quantity += 1;
                newSource[index].quantity -= 1;
            }
            else {
                newSource = [
                    ...products,
                    product
                ]
            }


            const newTarget = target.filter(item => item.id !== product.id);

            setTarget(newTarget);
            setProducts(newSource);
        }
        else {
            const newTarget = target.filter(item => item.id !== product.id);

            setTarget(newTarget);
        };
    };

    const calculateTotals = () => {
        const totals = {};

        target.forEach(product => {
            const quantity = product.quantity ?? 1;
            const saleAmount = product.sale_price * quantity;
            const currency = product.sale_price_currency;

            if (!totals[currency]) {
                totals[currency] = {
                    currency: currency,
                    sum: 0,
                    paymentMethod: null // Or any default value if needed
                };
            }

            totals[currency].sum += saleAmount;
        });

        return Object.values(totals);
    };

    const paymentPageHandler = () => {
        const totals = calculateTotals();
        formik.setFieldValue('products', target);
        formik.setFieldValue('basePayments', totals);
        formik.setFieldValue('payments', JSON.parse(JSON.stringify(totals)));

        let discounts = [];
        const existingDiscounts = location.state?.sale?.discount ? location.state.sale.discount : formik.values.discount;
        const existingCurrencyMap = new Map(existingDiscounts.map(discount => [discount.currency, discount.amount]));
        
        totals.forEach(total => {
            const currency = total.currency;
            const amountFromExisting = existingCurrencyMap.get(currency) || 0;

            discounts.push({
                currency: currency,
                amount: amountFromExisting
            });
        });

        if (location.state != null) {
            if (location.state.sale.cagent !== null) {
                formik.setFieldValue('client', location.state.sale.cagent);
            }

            formik.setFieldValue('isEdit', true);
            formik.setFieldValue('actDate', location.state.sale.act_date);
            formik.setFieldValue('actNumber', location.state.sale.act_number);
            formik.setFieldValue('payDueDate', location.state.sale.pay_due_date);
            formik.setFieldValue('saleId', location.state.sale.id);
            location.state = null;
        }

        let amountUAH = 0;
        totals.forEach(total => {
            amountUAH += total.sum * currencyRates[total.currency];
        });

        formik.setFieldValue("discount", discounts);

        setTotalSumUAH(amountUAH);
        stepperRef.current.nextCallback();
    };

    const sidebarHandler = (name) => {
        setSideBarType(name);
        setVisible(true);
    };

    const deleteSaleHandler = () => {
        const deleteAct = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "sale_acts/delete/" + formik.values.saleId + "/";
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);

            let message = "";
            if (response.status !== 204) {
                message = { severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 1000 };
            }
            else {
                message = { severity: "success", summary: 'Видалення', detail: 'Акт успішно видалено', life: 1000 };
            }

            navigate("/opensales", { state: { toast: message } })
        }

        deleteAct();
    }

    const itemTemplate = (item, type) => {
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                <div className="flex-1 flex flex-column gap-2">
                    <span className="font-bold">{item.total_quantity === undefined ? "" : <Badge severity="secondary" value={type === "source" ? item.available_quantity : item.quantity} />} {item.product_name}</span>
                    {(item.note !== undefined && item.note !== "") && <div className="flex align-items-center gap-2">
                        <i className="pi pi-bookmark text-sm"></i>
                        <span>{item.note}</span>
                    </div>}
                    <div className="flex align-items-center gap-2">
                        <i className="pi pi-tag text-sm"></i>
                        <span>{item.product_group}</span>
                    </div>
                </div>
                <span className="font-bold text-900">{formatCurrency(item.sale_price, item.sale_price_currency)}</span>
            </div>
        );
    };

    const HideSideBar = (type) => {
        if(type === "payment"){
            formik.setFieldValue("payments", unDiscountedPayments);
        }

        setVisible(false);
    }

    //#region Sidebar bodies

    const sideBarHeader = (
        <div className="flex">
            <div className="flex-1 flex align-items-center justify-content-center font-semibold">
                {sideBarType === "client" && "Виберіть клієнта"}
                {sideBarType === "discount" && "Виберіть знижку"}
                {sideBarType === "payment" && "Оплата товару"}
                {sideBarType === "quantity" && "Виберіть кількість товару"}
                {sideBarType === "delete" && "Видалення акту"}
            </div>
        </div>
    );

    const clientSideBarHeader = (
        <div className="grid flex">
            <div className='col-10'>
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText placeholder="Пошук" value={clientFilter} onChange={(e) => setClientFilter(e.target.value)} />
                </IconField>
            </div>
            <div className='col-2 justify-content-end'>
                <Button icon="pi pi-plus" rounded text aria-label="Filter" className="editButttonNoBackground" severity="info" onClick={addNewClient} />
            </div>
        </div>
    );

    const clientSideBarBody = (
        <>  
            {formik.values.client && <Button icon="pi pi-trash" severity="danger" className="pageButton deleteButton" onClick={clearClient}></Button>}
            <DataTable
                lazy
                rows={lazyState.rows} 
                paginator
                paginatorTemplate="RowsPerPageDropdown PrevPageLink CurrentPageReport NextPageLink"
                currentPageReportTemplate="{first} до {last}"
                onPage={onPage}
                sortField={lazyState.sortField} 
                sortOrder={lazyState.sortOrder}
                first={lazyState.first} 
                loading={loading} 
                totalRecords={totalRecords} 
                rowsPerPageOptions={[10, 20]}
                value={clients.filter(item => item.name.toLowerCase().includes(clientFilter.toLowerCase()))}
                header={clientSideBarHeader}
                selectionMode="single"
                selection={formik.values.client}
                onSelectionChange={onSelectionChange}
                dataKey="id"
            >
                <Column field="name" headerStyle={{ display: 'none' }}></Column>
            </DataTable>
        </>
    );

    const discountSideBarBody = () => (
        <>
            {formik.values.discount.map((currencyAmount, index) => (
                <div className="grid" key={index}>
                    <div className="col-10 p-inputgroup flex-1 mt-2 mb-2">
                        <span className="currencySpan p-inputgroup-addon">
                            <Dropdown
                                className="currencyDropDown"
                                value={currencyAmount.currency}
                                onChange={e => handleCurrencyChange(index, e.value)}
                                options={currencies.map(currency => ({ label: currency, value: currency }))}
                            />
                        </span>
                        <InputText
                            name={`currencyValuePair[${index}].amount`}
                            value={currencyAmount.amount}
                            onChange={e => handleAmountChange(index, e.target.value)}
                            className={formik.errors.currencyValuePair && formik.touched.currencyValuePair ? 'p-invalid' : ''}
                        />
                    </div>
                    <div className="col-2 mt-2 mb-2">
                        <Button className="noStyleButton" icon="pi pi-trash" severity="danger" type='button' button text onClick={() => handleAmountChange(index, 0)} />
                    </div>
                </div>
            ))}
        </>
    );

    const paymentBody = () => (
        <form onSubmit={formik.handleSubmit}>
            {formik.values.payments.length === 0 && (
                <div className="mb-2">
                <Message className='sideBarMessage' severity="error" text="Додайте валюту і спосіб оплати"/>
                </div>
            )}
            {formik.values.payments.map((currencyAmount, index) => (
                <div className="grid py-3" key={index}>
                    <div className="col-10 p-inputgroup py-0 flex-1">
                        <span className="currencySpan p-inputgroup-addon">
                            <Dropdown
                                className="currencyDropDown"
                                value={currencyAmount.currency}
                                onChange={e => handlePaymentsCurrencyChange(index, e.value)}
                                options={currencies.map(currency => ({ label: currency, value: currency }))}
                            />
                        </span>
                        <InputText
                            name={`currencyValuePair[${index}].amount`}
                            value={currencyAmount.sum}
                            onChange={e => handlePaymentsAmountChange(index, e.target.value)}
                            className={formik.errors.currencyValuePair && formik.touched.currencyValuePair ? 'p-invalid' : ''}
                        />
                    </div>
                    <div className="col-2">
                        <Button className="noStyleButton" icon="pi pi-trash" severity="danger" type='button' text onClick={() => deletePayment(index)} />
                    </div>
                    <div className="col-10 py-0">
                        <Dropdown
                            className={formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] ? 'paymentTypeDropDown p-invalid' : 'paymentTypeDropDown'}
                            value={currencyAmount.paymentMethod}
                            onChange={e => handlePaymentMethodChange(index, e.value)}
                            options={paymentMethods.map(method => ({ label: method, value: method }))}
                            placeholder="Спосіб оплати"
                        />
                        {formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] && (
                            <small className="errorSmall">{formik.errors.payments[index].paymentMethod}</small>
                        )}
                    </div>
                </div>
            ))}
            <div className='mb-2'>
                <Message className='sideBarMessage' severity={unDividedSum > 0 ? "warn" : "success"} text={`Нерозподілена сума: ${unDividedSum} грн.`} />
            </div>
            <div>
                <Button label="+ Додати валюту" severity="info" type="button" className="mainPageButton editButton" onClick={addPaymentToFormik} />
                {formik.values.payments.length > 0 && <Button label="Оплатити" severity="success" type="submit" className="mainPageButton addButton mt-1" />}
            </div>
        </form>
    );

    const quantityBody = () => (<form onSubmit={quantityFormik.handleSubmit}>
        <div className="grid">
            <div className="col-12 p-inputgroup flex-1 mt-2 mb-2">
                <FloatLabel className="mt-3">
                    <InputText value={quantityFormik.values.quantity}
                        onChange={e => quantityFormik.setFieldValue("quantity", e.target.value)}
                    />
                    <label>{quantityFormik.values.product.product_name}</label>
                </FloatLabel>
            </div>
            <Button className="pageButton editButton text-large font-medium" severity='info' type="submit">Вибрати кількість</Button>
        </div>
    </form>
    );

    const deleteBody = () => (
        <div className='grid'>
            <span className='mt-3 mb-2 text-800 text-large font-medium'> Підтвердіть видалення акту: #{formik.values.saleId}</span>
            <Button className="pageButton deleteButton text-large font-medium" severity='danger' type="button" onClick={deleteSaleHandler}>Видалити</Button>
        </div>
    )

    //#endregion

    const clientButtonName = `Обрати клієнта${formik.values.client?.name ? ` (${formik.values.client.name})` : ""}`;

    console.log(lazyState);

    return (
        <>
            <Toast ref={toast} />
            <Stepper linear ref={stepperRef} style={{ flexBasis: '50rem' }}>
                <StepperPanel header="Вибір товарів">
                    <div className="grid">
                        <div className="col-12 md:col-6 flex flex-column">
                            <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
                                <TabPanel className="panelStyle" header="Товари">
                                    <DataTable
                                        value={products.filter(item => item.product_name.toLowerCase().includes(sourceFilter.toLowerCase()))}
                                        header={sourceHeader}
                                        selectionMode="single"
                                        onSelectionChange={(e) => moveToTargetHandler(e.value)}
                                        sortField="product_name"
                                        sortOrder={1}
                                        dataKey="id"
                                        className="full-height-table"
                                        emptyMessage="Залишки товарів відсутні"
                                        scrollable
                                    >
                                        <Column sortable field="product_name" header="Назва" headerStyle={{ display: 'none' }} body={(rowData) => itemTemplate(rowData, 'source')} />
                                    </DataTable>
                                </TabPanel>
                                <TabPanel header="Послуги">
                                    <DataTable
                                        value={services.filter(item => item.product_name.toLowerCase().includes(sourceFilter.toLowerCase()))}
                                        header={sourceHeader}
                                        selectionMode="single"
                                        onSelectionChange={(e) => moveToTargetHandler(e.value)}
                                        sortField="product_name"
                                        sortOrder={1}
                                        dataKey="id"
                                        className="full-height-table"
                                        emptyMessage="Доступні сервіси відсутні"
                                        scrollable
                                    >
                                        <Column sortable field="product_name" header="Назва" headerStyle={{ display: 'none' }} body={(rowData) => itemTemplate(rowData, 'source')} />
                                    </DataTable>
                                </TabPanel>
                                <TabPanel header={<><i className="justify-items-center pi pi-barcode" /><i className="justify-items-center pi pi-barcode" /><i className="justify-items-center pi pi-barcode" /></>}>
                                    {devices.length === 0 && <label>Сканування неможливе. Пристрій немає камери</label>}
                                    {devices.length > 0 && <><label>Виберіть камеру: </label>
                                        <Dropdown
                                            value={deviceId}
                                            options={devices.map(device => ({ label: device.label, value: device.id }))}
                                            onChange={e => setDeviceId(e.value)}
                                            style={{ minWidth: '10rem' }}
                                            className='mt-2'
                                        />
                                        <video width="100%" height="auto" ref={ref} /></>}
                                </TabPanel>
                            </TabView>
                        </div>
                        <div className="col-12 md:col-6">
                            <div className="flex flex-column">
                                <DataTable
                                    value={target.filter(item => item.product_name.toLowerCase().includes(targetFilter.toLowerCase()))}
                                    header={targetHeader}
                                    selectionMode="single"
                                    onSelectionChange={(e) => moveToSourceHandler(e.value)}
                                    sortField="product_name"
                                    sortOrder={1}
                                    dataKey="id"
                                    className="full-height-table"
                                    emptyMessage="Оберіть товар в сусідньому меню"
                                    scrollable
                                >
                                    <Column sortable field="product_name" header="Назва" headerStyle={{ display: 'none' }} body={(rowData) => itemTemplate(rowData, 'target')} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="button-container">
                        <Button
                            disabled={target.length === 0}
                            label="Перейти до оплати"
                            icon="pi pi-arrow-right"
                            iconPos="right"
                            onClick={paymentPageHandler}
                        />
                    </div>
                </StepperPanel>
                <StepperPanel header="Оплата замовлення">
                    <div className='grid mt-2'>
                        <div className="col-8 col-offset-2">
                            <div className="surface-section">
                                <div className="text-center">
                                    <div className="font-medium text-xl text-900 mb-3">До оплати</div>
                                </div>
                                <ul className="list-none p-0 m-0">
                                    {formik.values.basePayments.map((payment, index) => (
                                        <li key={index} className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                                            <div className="text-500 w-6 md:w-2 font-medium">{payment.currency}</div>
                                            <div className="w-6 md:w-2 font-medium flex item-center justify-content-end ">
                                                <span className='mr-3 mt-2 text-800 text-large'>{formatCurrency(payment.sum, payment.currency)}</span>
                                            </div>
                                        </li>
                                    ))}
                                    <li key="totalUAH" className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                                        <div className="text-500 font-medium">Еквівалентна сума в гривні</div>
                                        <div className="w-6 md:w-2 font-medium flex item-center justify-content-end ">
                                            <span className='mr-3 mt-2 text-800 text-large'>{formatCurrency(totalSumUAH, "UAH")}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {existsDiscount() && (
                                <div className="surface-section">
                                    <div className="text-center">
                                        <div className="font-medium text-xl text-900">Знижка</div>
                                    </div>
                                    <ul className="list-none p-0 m-0">
                                        {formik.values.discount.map((discount, index) => (discount.amount > 0 &&
                                            <li key={`discount${index}`} className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                                                <div className="text-500 w-6 md:w-2 font-medium">{discount.currency}</div>
                                                <div className="w-6 md:w-2 font-medium flex text-500 justify-content-end">
                                                    {formatCurrency(discount.amount, discount.currency)}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <div className='grid'>
                                <div className='col-12 '>
                                    <Button className="pageButton editButton justify-content-center font-bold" severity="info" onClick={() => sidebarHandler("discount")}>Додати знижку</Button>
                                </div>
                            </div>
                            <div className='grid'>
                                <div className='col-12 '>
                                    <Button className="pageButton editButton justify-content-center font-bold" severity="info" onClick={() => sidebarHandler("client")}>{clientButtonName}</Button>
                                </div>
                            </div>
                            <div className='grid'>
                                {formik.values.isEdit && <div className='col-6 sm:col-6'>
                                    <Button className="pr-1 pl-1 pageButton editButton responsiveButton justify-content-center font-bold" severity="info" onClick={() => sidebarHandler("delete")}>Видалити</Button>
                                </div>}
                                <div className={formik.values.isEdit ? "col-6 sm:col-6" : "col-12 sm:col-12"}>
                                    <Button className="pr-1 pl-1 pageButton editButton responsiveButton justify-content-center font-bold" severity="info" onClick={() => showPaymentSideBar()}>Оплатити</Button>
                                </div>
                            </div>
                            <div className='grid'>
                                <div className='col-6'>
                                    <Button className="pr-1 pl-1 pageButton editButton justify-content-center font-bold" severity="info" label="Назад" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                                </div>
                                <div className='col-6'>
                                    <Button className="pr-1 pl-1 pageButton editButton justify-content-center font-bold" severity="info" onClick={() => saveDraft()}>Зберегти і вийти</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </StepperPanel>
            </Stepper>
            <Sidebar className="responsive-sidebar" header={sideBarHeader} visible={visible} position="right" onHide={() => HideSideBar(sideBarType)}>
                {sideBarType === "client" && clientSideBarBody}
                {sideBarType === "discount" && discountSideBarBody()}
                {sideBarType === "payment" && paymentBody()}
                {sideBarType === "quantity" && quantityBody()}
                {sideBarType === "delete" && deleteBody()}
            </Sidebar>
            <Sidebar className="responsive-sidebar" visible={clientSideBarVisible} position="right" onHide={() => setClientSideBarVisible(false)}>
            <form onSubmit={newUserFormik.handleSubmit}>
                <div className="radioButtonBlock">
                    <div className="accountingRadio">
                        <RadioButton value="INDIVIDUAL" checked={newUserFormik.values.type === "INDIVIDUAL"} onChange={(e) => { newUserFormik.setFieldValue('type', "INDIVIDUAL"); }} />
                        <label className="ml-2">Фізична особа</label>
                    </div>
                    <div className="accountingRadio">
                        <RadioButton value="LEGAL" checked={newUserFormik.values.type === "LEGAL"} onChange={(e) => { newUserFormik.setFieldValue('type', "LEGAL"); }} />
                        <label className="ml-2">Юридична особа</label>
                    </div>
                </div>
                {newUserFormik.values.type === "INDIVIDUAL" && (<>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="last_name"
                                    value={newUserFormik.values.individual_cagent_data?.last_name}
                                    onChange={(e) => { newUserFormik.setFieldValue('individual_cagent_data.last_name', e.target.value) }}
                                    className={newUserFormik.errors.indLastName && newUserFormik.touched.individual_cagent_data?.last_name ? 'p-invalid' : ''}
                                />
                                <label>Прізвище контрагента</label>
                            </FloatLabel>
                            {newUserFormik.errors.indLastName && newUserFormik.touched.individual_cagent_data?.last_name && (<small className="errorSmall">{newUserFormik.errors.indLastName}</small>)}
                        </div>
                    </div>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="first_name"
                                    value={newUserFormik.values.individual_cagent_data?.first_name}
                                    onChange={(e) => { newUserFormik.setFieldValue('individual_cagent_data.first_name', e.target.value) }}
                                    className={newUserFormik.errors.indFirstName && newUserFormik.touched.individual_cagent_data?.first_name ? 'p-invalid' : ''}
                                />
                                <label>Імя контрагента</label>
                            </FloatLabel>
                            {newUserFormik.errors.indFirstName && newUserFormik.touched.individual_cagent_data?.first_name && (<small className="errorSmall">{newUserFormik.errors.indFirstName}</small>)}
                        </div>
                    </div>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="surname"
                                    value={newUserFormik.values.individual_cagent_data?.surname}
                                    onChange={(e) => { newUserFormik.setFieldValue('individual_cagent_data.surname', e.target.value) }}
                                />
                                <label>По-батькові контрагента</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <Calendar
                                    value={newUserFormik.values.individual_cagent_data.date_of_birth}
                                    onChange={(e) => { newUserFormik.setFieldValue('individual_cagent_data.date_of_birth', e.target.value) }}
                                    showIcon
                                    locale="ua"
                                />
                                <label>Дата народження</label>
                            </FloatLabel>
                        </div>
                    </div>
                </>)}
                {newUserFormik.values.type === "LEGAL" && (<>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="long_name"
                                    value={newUserFormik.values.legal_cagent_data.long_name}
                                    onChange={(e) => { newUserFormik.setFieldValue('legal_cagent_data.long_name', e.target.value) }}
                                    className={newUserFormik.errors.legalLongName && newUserFormik.touched.legal_cagent_data?.long_name ? 'p-invalid' : ''}
                                />
                                <label>Повна назва контрагента</label>
                            </FloatLabel>
                            {newUserFormik.errors.legalLongName && newUserFormik.touched.legal_cagent_data?.long_name && (<small className="errorSmall">{newUserFormik.errors.legalLongName}</small>)}
                        </div>
                    </div>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="short_name"
                                    value={newUserFormik.values.legal_cagent_data.short_name}
                                    onChange={(e) => { newUserFormik.setFieldValue('legal_cagent_data.short_name', e.target.value) }}
                                    className={newUserFormik.errors.legalShortName && newUserFormik.touched.legal_cagent_data?.short_name ? 'p-invalid' : ''}
                                />
                                <label>Скорочена назва контрагента</label>
                            </FloatLabel>
                            {newUserFormik.errors.legalShortName && newUserFormik.touched.legal_cagent_data?.short_name && (<small className="errorSmall">{newUserFormik.errors.legalShortName}</small>)}
                        </div>
                    </div>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="representative"
                                    value={newUserFormik.values.legal_cagent_data.representative}
                                    onChange={(e) => { newUserFormik.setFieldValue('legal_cagent_data.representative', e.target.value) }}
                                />
                                <label>Представник</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="edrpou"
                                    value={newUserFormik.values.legal_cagent_data.edrpou}
                                    onChange={(e) => { newUserFormik.setFieldValue('legal_cagent_data.edrpou', e.target.value) }}
                                    className={newUserFormik.errors.legalEdrpou && newUserFormik.touched.legal_cagent_data?.edrpou ? 'p-invalid' : ''}
                                />
                                <label>ЄДРПОУ</label>
                            </FloatLabel>
                            {newUserFormik.errors.legalEdrpou && newUserFormik.touched.legal_cagent_data?.edrpou && (<small className="errorSmall">{newUserFormik.errors.legalEdrpou}</small>)}
                        </div>
                    </div>
                </>)}
                <Button className="pr-1 pl-1 pageButton editButton justify-content-center font-bold" severity="info" type="submit">Створити клієнта</Button>
                </form>
            </Sidebar>
        </>
    );
};

export default NewSale;