import Cookies from 'js-cookie';
import useAuthenticatedRequest from './TokenRefreshUtils';
import { useShift } from '../context/ShiftProvider';

const useValidateShift = () => {
    const authenticatedRequest = useAuthenticatedRequest();
    const { closeShift } = useShift();

    const validateShift = async () => {
        const url = `${process.env.REACT_APP_BACKEND_URL}kasa_shifts/get_current_shift_simplified_data/`;
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();
            console.log(parsedResponse);
            if (parsedResponse.id === undefined) {
                closeShift();
                return false;
            } else {
                if (Cookies.get('ShiftId') !== parsedResponse.id) {
                    Cookies.set('ShiftId', parsedResponse.id);
                }
                return true;
            }
        } catch (error) {
            console.error('Validation error:', error);
            return false;
        }
    };

    return validateShift;
};

export default useValidateShift;