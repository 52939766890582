import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuthenticatedRequest from "../../utils/TokenRefreshUtils";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { TabPanel } from "primereact/tabview";
import { TabView } from "primereact/tabview";
import { formatCurrency, formatDate } from "../../utils/CommonUtils";
import { TranslateTransType } from "../../utils/TranslationUtils";


const SaleHistory = () => {

    const navigate = useNavigate();

    const authenticatedRequest = useAuthenticatedRequest();
    const [shiftsHistory, setShiftsHistory] = useState([]);
    const [shiftDetails, setShiftDetails] = useState(null);
    const [shiftTransactions, setShiftTransactions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [selectedShift, setSelectedShift] = useState(null);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1
    });
    const [transTotalRecords, setTransTotalRecords] = useState(0);
    const [transloading, setTransLoading] = useState(true);
    const [transLazyState, setTransLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1
    });

    useEffect(() => {

        const fetchData = async () => {
            const url = `${process.env.REACT_APP_BACKEND_URL}kasa_shifts/get_shifts_history/?page=${lazyState.page}&page_size=${lazyState.rows}&ordering=-close_time`;
            const options = { method: "GET", headers: { 'Content-Type': 'application/json' } };
            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();
            setTotalRecords(parsedResponse.count);
            setShiftsHistory(parsedResponse.results);
            setLoading(false);
        };

        fetchData();
    }, [authenticatedRequest, lazyState.page, lazyState.rows]);

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onTransPage = (event) => {
        event.page = event.page + 1;
        setTransLazyState(event);
        fetchTransaction(selectedShift, event.page, event.rows);
    }

    const fetchTransaction = async (id, page, rows) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}kasa_shifts/get_shift_transactions_history/?shift_id=${id}&page=${page}&page_size=${rows}&ordering=-made_at`;
        const options = { method: "GET", headers: { 'Content-Type': 'application/json' } };
        const response = await authenticatedRequest(url, options);
        const parsedResponse = await response.json();
        setTransTotalRecords(parsedResponse.count);
        setShiftTransactions(parsedResponse.results);
        setTransLoading(false);
    };

    const getDetailsHandler = (rowData) => {

        setSelectedShift(rowData.id);

        const fetchHistory = async () => {
            const url = `${process.env.REACT_APP_BACKEND_URL}kasa_shifts/get_shift_history_details/${rowData.id}/`;
            const options = { method: "GET", headers: { 'Content-Type': 'application/json' } };
            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();
            console.log(parsedResponse);
            setShiftDetails(parsedResponse);
        };

        fetchHistory();
        fetchTransaction(rowData.id, transLazyState.page, transLazyState.rows);
    }

    const OpenSaleDetails = (item) => {
        if(item.type === "SALE"){
            const route = '/salehistory/sale/' + item.act;
            navigate(route, { state: { id: item.act } });
        }
    }

    const itemTemplate = (item) =>
    (<div className="flex flex-wrap p-2 align-items-center gap-3">
        <div className="flex-1 flex flex-column gap-2">
            <span className="font-bold">{`Зміна (${formatDate(item.open_time) + (item.close_time !== null ? "-" + formatDate(item.close_time) : "")})`}</span>
            <div className="flex align-items-center gap-2">
                <i className="pi pi-cog text-sm"></i>
                <span>{`Статус: ${item.status === "CLOSED" ? "Закрита" : "Відкрита"}`}</span>
            </div>
        </div>
    </div>
    );
    
    const transactionTemplate = (item) => {
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3" onClick={() => OpenSaleDetails(item)}>
                <div className="flex-1 flex flex-column gap-2">
                    <span className="font-bold">{`${TranslateTransType(item.type)} (${item.made_by})`}</span>
                    <span className="font-bold"><i className="pi pi-clock text-sm"/> {`${formatDate(item.made_at)}`}</span>
                    {item.type === "SALE" && <div className="flex align-items-center gap-2">
                        <i className="pi pi-tag text-sm"></i>
                        <span>Акт {item.act} {item.cagent !== null ? item.cagent : ""}</span>
                    </div>}
                </div>
                <span className="font-bold text-900">{formatCurrency(item.sum, item.currency)}</span>
            </div>
        );
    }

    const tableHeader = (name) => {
        return <div>{name}</div>
    }

    const shiftDetailsBuilder = (object) => {
        const firstElement = <div className="w-6 font-medium flex text-500 justify-content-end">{formatCurrency(object[0].sum, object[0].currency)}</div>;

        const furtherElements = [];

        if (object.length > 1) {
            // Loop through the rest of the objects
            for (let index = 1; index < object.length; index++) {
                furtherElements.push(
                    <React.Fragment key={index}>
                        <div className="w-6"></div>
                        <div className="w-6 font-medium flex text-500 justify-content-end">
                            {formatCurrency(object[index].sum, object[index].currency)}
                        </div>
                    </React.Fragment>
                );
            }
        }

        // Return all elements as a React fragment
        return (
            <>
                {firstElement}
                {furtherElements}
            </>
        );
    }

    return (<>
        <div className="grid card">
            <div className="col-6 flex flex-column">
                <DataTable
                    value={shiftsHistory}
                    header={tableHeader("Історія змін")}
                    selectionMode="single"
                    onSelectionChange={(e) => getDetailsHandler(e.value)}
                    dataKey="id"
                    lazy
                    paginator
                    rows={lazyState.rows}
                    first={lazyState.first}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    loading={loading}
                >
                    <Column field="close_time" body={itemTemplate} />
                </DataTable>
            </div>
            <div className="col-6 card">
                <div className="flex flex-column">
                    <TabView>
                        <TabPanel header="Загальні дані">
                            {shiftDetails !== null && <div className="surface-section">
                                <ul className="list-none p-0 m-0">
                                    <li key="open" className="flex flex-wrap justify-content-between align-items-center py-3 px-2 border-top-1 surface-border">
                                        <div className="text-500 w-6 font-medium">Зміна відкрита</div>
                                        <div className="w-6 font-medium flex text-500 justify-content-end">{formatDate(shiftDetails.open_time)}</div>
                                        <div className="text-500 w-6 font-medium"></div>
                                        <div className="w-6 font-medium flex text-500 justify-content-end">{shiftDetails.opened_by}</div>
                                    </li>
                                    {shiftDetails.close_time != null && <li key="close" className="flex flex-wrap justify-content-between align-items-center py-3 px-2 border-top-1 surface-border">
                                        <div className="text-500 w-6 font-medium">Зміна закрита</div>
                                        <div className="w-6 font-medium flex text-500 justify-content-end">{formatDate(shiftDetails.close_time)}</div>
                                        <div className="w-6"></div>
                                        <div className="w-6 font-medium flex text-500 justify-content-end">{shiftDetails.closed_by}</div>
                                    </li>}
                                    <li key="stBalance" className="flex flex-wrap justify-content-between align-items-center py-3 px-2 border-top-1 surface-border">
                                        <div className="text-500 w-6 font-medium">Початковий баланс</div>
                                        {shiftDetailsBuilder(shiftDetails.balance_on_open)}
                                    </li>
                                    {shiftDetails.balance_on_close != null && <li key="endBalance" className="flex flex-wrap justify-content-between align-items-center py-3 px-2 border-top-1 surface-border">
                                        <div className="text-500 w-6 font-medium">Баланс на кінець</div>
                                        {shiftDetailsBuilder(shiftDetails.balance_on_close)}
                                    </li>}
                                    {shiftDetails.sales != null && <li key="sales" className="flex flex-wrap justify-content-between align-items-center py-3 px-2 border-top-1 surface-border">
                                        <div className="text-500 w-6 font-medium">Продажі</div>
                                        {shiftDetailsBuilder(shiftDetails.sales.amount)}
                                    </li>}
                                    {shiftDetails.cash_deposits != null && <li key="deposits" className="flex flex-wrap justify-content-between align-items-center py-3 px-2 border-top-1 surface-border">
                                        <div className="text-500 w-6 font-medium">Внесення</div>
                                        {shiftDetailsBuilder(shiftDetails.cash_deposits.amount)}
                                    </li>}
                                    {shiftDetails.cash_withdraws != null && <li key="withdrawals" className="flex flex-wrap justify-content-between align-items-center py-3 px-2 border-top-1 surface-border">
                                        <div className="text-500 w-6 font-medium">Зняття</div>
                                        {shiftDetailsBuilder(shiftDetails.cash_withdraws.amount)}
                                    </li>}
                                    {shiftDetails.revenue != null && <li key="revenue" className="flex flex-wrap justify-content-between align-items-center py-3 px-2 border-top-1 surface-border">
                                        <div className="text-500 w-6 font-medium">Виручка</div>
                                        {shiftDetailsBuilder(shiftDetails.revenue.amount)}
                                    </li>}
                                </ul>
                            </div>}
                        </TabPanel>
                        <TabPanel header="Транзакції">
                            <DataTable
                                value={shiftTransactions}
                                lazy
                                paginator
                                rows={transLazyState.rows}
                                first={transLazyState.first}
                                onPage={onTransPage}
                                totalRecords={transTotalRecords}
                                loading={transloading}
                                emptyMessage="Транзакцій не знайдено"
                            >
                                <Column field="made_at" body={transactionTemplate} />
                            </DataTable>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    </>)
}

export default SaleHistory;