import { useRef } from "react";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import rctLogo from "../../images/RCTLogo.png";
import Background from "../../images/background.jpg";
import { useFormik } from "formik";
import { useAuth } from "../../context/AuthProvider";
import { Toast } from "primereact/toast";


const Authorization = () => {

    const toast = useRef(null);

    const divStyle = {
        backgroundImage: `url(${Background})`, // Use imported image as background
        backgroundSize: 'cover', // Cover the entire content area of the div
        height: '100%', // Full height of the parent container
      };

    const auth = useAuth();

    const formik = useFormik({
        initialValues: {
            username: "",
            password: ""
        },
        validate: values => {
            const errors = {};

            if (values.username === "") {
                errors.username = "Введіть логін"
            }

            if (values.password === "") {
                errors.password = "Введіть пароль користувача"
            }
        },
        onSubmit: (values) => {

            const loginUser = async () => {
                const response = await auth.loginAction(values);

                if(response === 401){
                    formik.setFieldValue("password", "");
                    toast.current.show({ severity: "error", summary: "Помилка авторизації", detail: "Неправильний логін або пароль користувача", life: 2000 });
                }
            }

            loginUser();
        }
    });

    return (<>
        <Toast ref={toast}/>
        <form onSubmit={formik.handleSubmit}>
            <div className="grid h-screen bg-black-alpha-90">
                <div className="col-12 lg:col-5 md:col-8 bg-black-alpha-90">
                    <div className="align-items-center flex justify-content-center md:px-6 px-4 py-8 ng-star-inserted">
                        <div className="surface-card p-4 shadow-2 border-round w-full">
                            <div className="text-center mb-5">
                                <img src={rctLogo} alt="RCTCRM" height="50" className="mb-3"></img>
                                <div className="text-900 text-3xl font-medium mb-3">Welcome to RCT Cash</div>
                            </div>

                            <div>
                                <label className="block text-900 font-medium mb-2">Логін</label>
                                <InputText
                                    type="text"
                                    placeholder="Логін"
                                    value={formik.values.username}
                                    onChange={(e) => { formik.setFieldValue('username', e.target.value) }}
                                    className="w-full mb-3" />

                                <label className="block text-900 font-medium mb-2">Пароль</label>
                                <InputText
                                    type="password"
                                    placeholder="Пароль"
                                    value={formik.values.password}
                                    onChange={(e) => { formik.setFieldValue('password', e.target.value) }}
                                    className="w-full mb-3" />
                                <Button label="Sign In" icon="pi pi-user" severity="danger" className="w-full" type="submit" />
                                <div className="flex justify-content-center">
                                    <p className="text-400"><small>&copy; 2024 Red Cat Tech. Версія системи 1.4.0</small> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-0 col-0 lg:col-7 md:col-4">
                    <div className="w-full" style={divStyle}></div>
                </div>
            </div>
        </form>
    </>
    );
}

export default Authorization;