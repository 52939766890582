// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableContainer {
    height: 70vh
}
.buttonsContainer {
    height: 20vh
}

.p-inputgroup-addon{
    min-width: 6rem;
}`, "",{"version":3,"sources":["webpack://./src/components/OpenSales/OpenSales.css"],"names":[],"mappings":"AAAA;IACI;AACJ;AACA;IACI;AACJ;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".tableContainer {\n    height: 70vh\n}\n.buttonsContainer {\n    height: 20vh\n}\n\n.p-inputgroup-addon{\n    min-width: 6rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
