import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

import AuthProvider from './context/AuthProvider';
import Authorization from './components/Authorization/Authorization';
import Layout from './components/Layout';
import PrivateRoute from './router/PrivateRoute';
import Shift from './components/Shift/Shift';
import NewSale from './components/NewSale/NewSale';
import SaleHistory from './components/SaleHistory/SaleHistory';
import { ShiftProvider } from './context/ShiftProvider';
import OpenSales from './components/OpenSales/OpenSales';
import SaleDetails from './components/SaleHistory/SaleDetails';


function App() {
  return (
    <PrimeReactProvider>
      <Router>
        <AuthProvider>
          <ShiftProvider>
            <Layout />
            <Routes>
              <Route path="/authorization" element={<Authorization />} />
              <Route element={<PrivateRoute />}>
                <Route exact path="/shift" element={<Shift />} />
                <Route exact path="/newsale" element={<NewSale />} />
                <Route exact path="/salehistory" element={<SaleHistory />} />
                <Route exact path="/salehistory/sale/:id" element={<SaleDetails/>} />
                <Route exact path="/opensales" element={<OpenSales />} />
                <Route path="*" element={<Shift />} />
              </Route>
            </Routes>
          </ShiftProvider>
        </AuthProvider>
      </Router>
    </PrimeReactProvider>
  );
}

export default App;
