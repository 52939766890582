import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

// Create the context
const ShiftContext = createContext();

// Provide context to children
export const ShiftProvider = ({ children }) => {
    const [isShiftOpen, setIsShiftOpen] = useState(Cookies.get("ShiftId") !== undefined);

    useEffect(() => {
        const shiftId = Cookies.get("ShiftId");
        setIsShiftOpen(shiftId !== undefined);
    }, []);

    const openShift = (id) => {
        if (typeof id === 'string' || typeof id === 'number') {
            Cookies.set("ShiftId", id.toString()); // Convert to string to avoid issues
            setIsShiftOpen(true);
        } else {
            console.error('Invalid ID:', id);
        }
    }

    const closeShift = () => {
        Cookies.remove("ShiftId");
        setIsShiftOpen(false);
    };

    return (
        <ShiftContext.Provider value={{ isShiftOpen, openShift, closeShift }}>
            {children}
        </ShiftContext.Provider>
    );
};

// Custom hook to use shift context
export const useShift = () => useContext(ShiftContext);