import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import useAuthenticatedRequest from '../../utils/TokenRefreshUtils';
import useValidateShift from '../../utils/ShiftUtils';
import { useEffect, useRef, useState } from 'react';
import { useShift } from '../../context/ShiftProvider';
import Cookies from 'js-cookie';
import { formatCurrency } from "../../utils/CommonUtils";

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from 'primereact/inputtext';
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";

import "./Shift.css";

const Shift = () => {

    const { isShiftOpen, openShift, closeShift } = useShift();
    const authenticatedRequest = useAuthenticatedRequest();
    const validateShift = useValidateShift();

    const toast = useRef(null);
    const location = useLocation();

    const currencies = ["UAH", "USD", "EUR"];
    const [shiftData, setShiftData] = useState([]);
    const [visible, setVisible] = useState();
    const [shiftSidebarVisible, setShiftSidebarVisible] = useState(false);
    const [isAddCash, setIsAddCash] = useState(true);
    const [reloadValues, setReloadValues] = useState(false);
    const [withdrawObject, setWithdrawObject] = useState([]);

    useEffect(() => {
        if(location.state !== null){
            toast.current.show(location.state.toast);
        }
  
        location.state = null;
    },[location.state, location]);

    useEffect(() => {
        const fetchData = async () => {
            const url = process.env.REACT_APP_BACKEND_URL + "kasa_shifts/get_current_shift_data/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();

            // logging to remove later
            console.log(parsedResponse);

            if (parsedResponse.shift_data !== null)
            {
                if(Cookies.get("ShiftId") === undefined){
                    Cookies.set("ShiftId", parsedResponse.shift_data.id);
                }

                openShift(parsedResponse.shift_data.id);
            }
            else{
                closeShift();
            }

            setShiftData(parsedResponse);

            let withdrawObject = [];
            parsedResponse.current_balance.forEach(element => {
                var currencyValues = {};
                currencyValues.sum = 0.00;
                currencyValues.currency = element.currency;
                currencyValues.availableSum = element.sum;

                withdrawObject = [
                    ...withdrawObject,
                    currencyValues
                ]
            });
            setWithdrawObject(withdrawObject);
        }

        fetchData();
    }, [authenticatedRequest, reloadValues, openShift, closeShift]);

    const depositformik = useFormik({
        initialValues: {
            currencyValuePair: [
                {
                    currency: "UAH",
                    sum: 0.00
                }
            ]
        },
        enableReinitialize: true,
        onSubmit: (values) => {

            const depositMoney = async () => {

                const isShiftValid = await validateShift();
                if (!isShiftValid) {
                    toast.current.show({ severity: "error", summary: "Помилка зміни", detail: "Зміна закрита", life: 3000 });
                } else {

                    const url = process.env.REACT_APP_BACKEND_URL + "kasa_shifts/create_cash_deposit_or_withdraw/";
                    const options = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            shift_id: Cookies.get("ShiftId"),
                            sums: values.currencyValuePair,
                            type: "CASH_DEPOSIT"
                        })
                    };

                    const response = await authenticatedRequest(url, options);

                    // logging to remove later

                    if (response.status !== 201) {
                        toast.current.show({ severity: "error", summary: "Помилка внесення грошей в касу", detail: "Помилка сервера " + response.status, life: 3000 });
                    }
                    else {
                        toast.current.show({ severity: "success", summary: 'Успіх', detail: 'Гроші успішно внесено в касу', life: 3000 });
                    }

                    setReloadValues(!reloadValues);
                }
            }

            depositMoney();

            setVisible(false);
            depositformik.resetForm();
        }
    });

    const withdrawalFormik = useFormik({
        initialValues: {
            currencyValuePair: withdrawObject
        },
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            values.currencyValuePair.forEach((object, index) => {
                const fieldErrors = {};

                if (object.sum === "" || isNaN(object.sum) || object.sum < 0) {
                    fieldErrors.amount = 'Сума повинна бути додатнім числом';
                } else if (object.sum > object.availableSum) {
                    fieldErrors.amount = `Сума у валюті ${object.currency} не повинна перевищувати: ${object.availableSum}`;
                }

                if (Object.keys(fieldErrors).length) {
                    if (errors.currencyValuePair === undefined) {
                        errors.currencyValuePair = {};
                    }
                    errors.currencyValuePair[index] = fieldErrors;
                }
            });

            return errors;
        },
        onSubmit: (values) => {

            let sumsToPass = [];

            values.currencyValuePair.forEach(pair => {
                if (pair.sum !== 0) {
                    sumsToPass = [
                        ...sumsToPass,
                        pair
                    ]
                }
            });

            const withdrawMoney = async () => {

                const isShiftValid = await validateShift();
                if (!isShiftValid) {
                    toast.current.show({ severity: "error", summary: "Помилка зміни", detail: "Зміна закрита", life: 3000 });
                } else {

                    const url = process.env.REACT_APP_BACKEND_URL + "kasa_shifts/create_cash_deposit_or_withdraw/";
                    const options = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            shift_id: Cookies.get("ShiftId"),
                            sums: sumsToPass,
                            type: "CASH_WITHDRAW"
                        })
                    };

                    const response = await authenticatedRequest(url, options);

                    // logging to remove later

                    if (response.status !== 201) {
                        toast.current.show({ severity: "error", summary: "Помилка забирання грошей з каси", detail: "Помилка сервера " + response.status, life: 3000 });
                    }
                    else {
                        toast.current.show({ severity: "success", summary: 'Успіх', detail: 'Гроші успішно вилучено з каси', life: 3000 });
                    }

                    setReloadValues(!reloadValues);
                }
            }

            withdrawMoney();
            setVisible(false);
            withdrawalFormik.resetForm();
        }
    });

    const handleCurrencyChange = (index, value) => {
        const newCurrencyValuePair = [...depositformik.values.currencyValuePair];
        newCurrencyValuePair[index].currency = value;
        depositformik.setFieldValue('currencyValuePair', newCurrencyValuePair);
    };

    const handleAmountChange = (index, formik, value) => {
        console.log(formik);
        const newCurrencyValuePair = [...formik.values.currencyValuePair];
        newCurrencyValuePair[index].sum = value;
        formik.setFieldValue('currencyValuePair', newCurrencyValuePair);
    };

    const deleteCurrencyAmountPair = (index) => {
        const newCurrencyValuePair = [...depositformik.values.currencyValuePair];
        console.log(index);
        console.log(newCurrencyValuePair[index]);
        newCurrencyValuePair.splice(index, 1);
        depositformik.setFieldValue('currencyValuePair', newCurrencyValuePair);
    }

    const addValueToFormik = () => {
        const newCurrencyValuePair = [
            ...depositformik.values.currencyValuePair,
            {
                currency: "UAH",
                sum: 0.00
            }
        ];

        depositformik.setFieldValue('currencyValuePair', newCurrencyValuePair);
    }

    const SidebarShowHabdler = (action) => {
        setIsAddCash(action);
        setVisible(true);
    }

    const closeSideBarHandler = () => {
        setVisible(false);
    }

    const changeShiftStatus = () => {
        const fetchData = async () => {

            const action = isShiftOpen ? "kasa_shifts/close_shift/" : "kasa_shifts/open_shift/";
            const url = process.env.REACT_APP_BACKEND_URL + action;
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();

            if (response.status === 201) {
                if (isShiftOpen) {
                    closeShift();
                }
                else{
                    const shiftId = parsedResponse.id
                    openShift(shiftId)
                }
            }
        }

        fetchData();
        setShiftSidebarVisible(false);

        setTimeout(() => {
            setReloadValues(!reloadValues);
        }, 500);
        setVisible(false);
    }

    const header = (name, value) => {
        return (<div className="flex justify-content-between flex-wrap">
            <div className="flex align-items-center justify-content-center">
                <span className="font-bold">{name}</span>
            </div>
            <div className="flex align-items-center justify-content-center ">
                <span className="font-bold">{value}</span>
            </div>
        </div>)
    }

    const SideBarHeader = (
        <div className="flex">
            <div className="flex-1 flex align-items-center justify-content-center font-semibold">{isAddCash ? "Внести готівку" : "Забрати готівку"}</div>
        </div>
    );

    const ShiftHeader = (
        <div className="flex">
            <div className="flex-1 flex align-items-center justify-content-center font-semibold">{isShiftOpen ? "Закрити зміну" : "Відкрити зміну"}</div>
        </div>
    );

    const depositMoneyContent = (
        <form onSubmit={depositformik.handleSubmit}>
            {depositformik.values.currencyValuePair.map((currencyAmount, index) => (
                <div className="grid" key={index}>
                    <div className="col-10 p-inputgroup flex-1 mt-2 mb-2">
                        <span className="currencySpan p-inputgroup-addon">
                            <Dropdown
                                className="currencyDropDown"
                                value={currencyAmount.currency}
                                onChange={e => handleCurrencyChange(index, e.value)}
                                options={currencies}
                            />
                        </span>
                        <InputText
                            name={`currencyValuePair[${index}].amount`}
                            value={currencyAmount.sum}
                            onChange={e => handleAmountChange(index, depositformik, e.target.value)}
                            className={depositformik.errors.currencyValuePair && depositformik.touched.currencyValuePair ? 'p-invalid' : ''}
                        />
                        {depositformik.errors.currencyValuePair && depositformik.touched.currencyValuePair && (
                            <small className="errorSmall">{depositformik.errors.currencyValuePair}</small>
                        )}
                    </div>
                    <div className="col-2 mt-2 mb-2">
                        <Button className="noStyleButton" icon="pi pi-trash" severity="danger" type='button' button text onClick={() => deleteCurrencyAmountPair(index, depositformik)}/>
                    </div>
                </div>
            ))}
            <div>
                <Button label="+ Додати значення" severity="info" type="button" className="mainPageButton editButton" onClick={addValueToFormik} />
                <Button label="Внести готівку" severity="success" type="submit" className="mainPageButton addButton mt-1" />
            </div>
        </form>);

    const withdrawMoneyContent = (
        <form onSubmit={withdrawalFormik.handleSubmit}>
            {withdrawalFormik.values.currencyValuePair?.map((object, index) => (
                <div className="grid flex-column" key={index}>
                    {!withdrawalFormik.errors.currencyValuePair?.[index]?.amount && (
                        <small className="messageSmall">MAX: {object.availableSum} {object.currency}</small>
                    )}
                    <div className="p-inputgroup flex-1 mt-2 mb-2">
                        <span className="currencySpan p-inputgroup-addon">
                            <Dropdown
                                className="currencyDropDown"
                                value={object.currency}
                                disabled
                                options={currencies}
                            />
                        </span>
                        <InputText
                            name={`currencyValuePair[${index}].amount`}
                            value={object.sum}
                            onChange={e => handleAmountChange(index, withdrawalFormik, e.target.value)}
                            className={withdrawalFormik.errors.currencyValuePair?.[index]?.amount ? 'p-invalid' : ''}
                        />
                    </div>
                    {withdrawalFormik.errors.currencyValuePair?.[index]?.amount && (
                        <small className="errorSmall">{withdrawalFormik.errors.currencyValuePair[index].amount}</small>
                    )}
                </div>
            ))}
            <div>
                <Button label={"Забрати готівку"} severity="success" type="submit" className="mainPageButton addButton mt-1" />
            </div>
        </form>
    );

    return (<>
        <Toast ref={toast} />
        <div className='grid'>
            <div className='col-8 col-offset-2'>
                <div className="mainPageButtonDiv">
                    <Button className="mainPageButton editButton font-bold" label={isShiftOpen ? "Закрити зміну" : "Відкрити зміну"} onClick={() => { setShiftSidebarVisible(true) }} severity="info" />
                </div>
            </div>
        </div>
        {isShiftOpen && <><div className='grid'>
            <div className="col-8 col-offset-2">
                <div className="mainPageButtonDiv">
                    <Button className="mainPageButton addButton" label="Внести гроші" onClick={() => { SidebarShowHabdler(true) }} severity="success" />
                </div>
            </div>
        </div>
            <div className='grid'>
                <div className="col-8 col-offset-2">
                    <div className="mainPageButtonDiv">
                        <Button className="mainPageButton deleteButton" label="Забрати гроші" onClick={() => { SidebarShowHabdler(false) }} severity="danger" />
                    </div>
                </div>
            </div></>}
        <div className='grid mt-2'>
            <div className="col-8 col-offset-2">
                <div className="surface-section">
                    <div className="font-medium text-2xl text-900 mb-3">Гроші в касі</div>
                    <ul className="list-none p-0 m-0">
                        {shiftData.current_balance?.map((object) => (
                            <li key={object?.currency + "-" + object.sum} className="flex flex-wrap justify-content-between align-items-center py-3 px-2 border-top-1 surface-border">
                                <div className="text-500 w-6 md:w-2 font-medium">{object?.currency}</div>
                                <div className="w-6 md:w-2 font-medium flex text-500 justify-content-end">{Number(object.sum)?.toFixed(2)}</div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
        {isShiftOpen && <>
            {shiftData.sales?.amount.length > 0 && <div className='grid'>
                <div className="col-8 col-offset-2">
                    <Accordion className="accordionStyle mt-1">
                        <AccordionTab header={() => header("Продажі", shiftData.sales?.quantity)}>
                            {shiftData.sales?.amount.map((object) => (<div key={"s" + object.currency} className="flex justify-content-between flex-wrap mt-1">
                                <div className="flex align-items-center justify-content-center">
                                    <span>{object.currency}</span>
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <span>{formatCurrency(object.sum, object.currency)}</span>
                                </div>
                            </div>))}
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>}
            {shiftData.cash_deposits?.amount.length > 0 && <div className='grid'>
                <div className="col-8 col-offset-2">
                    <Accordion className="accordionStyle mt-1">
                        <AccordionTab header={() => header("Внесення", shiftData.cash_deposits?.quantity)}>
                            {shiftData.cash_deposits?.amount.map((object) => (<div key={"d" + object.currency} className="flex justify-content-between flex-wrap mt-1">
                                <div className="flex align-items-center justify-content-center">
                                    <span>{object.currency}</span>
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <span>{formatCurrency(object.sum, object.currency)}</span>
                                </div>
                            </div>))}
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>}
            {shiftData.cash_withdraws?.amount.length > 0 && <div className='grid'>
                <div className="col-8 col-offset-2">
                    <Accordion className="accordionStyle mt-1">
                        <AccordionTab header={() => header("Виплати", shiftData.cash_withdraws?.quantity)}>
                            {shiftData.cash_withdraws?.amount.map((object) => (<div key={"p" + object.currency} className="flex justify-content-between flex-wrap mt-1">
                                <div className="flex align-items-center justify-content-center">
                                    <span>{object.currency}</span>
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <span>{formatCurrency(object.sum, object.currency)}</span>
                                </div>
                            </div>))}
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>}
            {shiftData.revenue?.length > 0 && <div className='grid'>
                <div className="col-8 col-offset-2">
                    <Accordion className="accordionStyle mt-1">
                        <AccordionTab header={() => header("Виручка", shiftData.revenue?.length)}>
                            {shiftData.revenue?.map((object) => (
                                <div key={"i" + object.currency} className="flex justify-content-between flex-wrap mt-1">
                                    <div className="flex align-items-center justify-content-center">
                                        <span>{object.currency}</span>
                                    </div>
                                    <div className="flex align-items-center justify-content-center">
                                        <span>{formatCurrency(object.sum, object.currency)}</span>
                                    </div>
                                </div>))}
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>}
        </>}
        <Sidebar
            header={SideBarHeader}
            visible={visible}
            position="right"
            onHide={() => closeSideBarHandler()}
        >
            {isAddCash ? depositMoneyContent : withdrawMoneyContent}
        </Sidebar>
        <Sidebar header={ShiftHeader} visible={shiftSidebarVisible} position="right" onHide={() => setShiftSidebarVisible(false)}>
            <div>
                <p>Підтвердіть {isShiftOpen ? "закриття" : "відкриття"} зміни</p>
                <Button label={isShiftOpen ? "Закрити зміну" : "Відкрити зміну"} severity="info" type="button" className="mainPageButton editButton mt-1" onClick={() => changeShiftStatus()} />
            </div>
        </Sidebar>
    </>);
}

export default Shift;

