import { useAuth } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useShift } from '../context/ShiftProvider';
import { useState } from 'react';

import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
import { Sidebar } from 'primereact/sidebar';
import { Toolbar } from 'primereact/toolbar';

import "./Layout.css";
import rctLogo from "../images/RCTLogo.png";

const Layout = () => {

    const auth = useAuth();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);

    const { isShiftOpen } = useShift();

    const startContent = (
        <Button icon="pi pi-bars" severity="secondary" outlined className="mr-2" onClick={() => setVisible(true)} />
    );

    const centerContent = isShiftOpen ? (
        <div>Зміна відкрита</div>
    ) : (
        null
    );

    const endContent = (
        <Button icon="pi pi-power-off" severity="secondary" outlined className="mr-2" onClick={() => auth.logOut()}/>
    )

    const handleNavigation = (path) => {
        navigate(path);
        setVisible(false);
    }

    if (!auth.token) {
        return <div></div>
    }
    else
    {
    return (
        <>
            <Sidebar visible={visible} onHide={() => setVisible(false)}
                content={({ hide }) => (
                    <div className="min-h-screen flex relative surface-ground">
                        <div id="app-sidebar-2" className="surface-section h-screen block flex-shrink-0 absolute left-0 top-0 z-1 border-right-1 surface-border select-none" style={{ width: '100%' }}>
                            <div className="flex flex-column h-full">
                                <div className="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
                                    <span className="inline-flex align-items-center gap-2">
                                        <img src={rctLogo} width="40" height="40" alt='rctLogo'/>
                                        <span className="font-semibold text-2xl text-black-alpha-90">RCT<span className="font-semibold text-2xl text-red-500">CASH</span></span>
                                    </span>
                                    <span>
                                        <Button type="button" onClick={(e) => hide(e)} icon="pi pi-times" severity='secondary' rounded outlined className="h-2rem w-2rem"></Button>
                                    </span>
                                </div>
                                <div className="overflow-y-auto">
                                    <ul className="list-none p-3 m-0 overflow-hidden">
                                        <li>
                                            <span className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors w-full" onClick={() => handleNavigation("/shift")}>
                                                <i className="pi pi-home mr-2"></i>
                                                <span className="font-medium">Зміна</span>
                                                <Ripple />
                                            </span>
                                        </li>
                                        {isShiftOpen && <li>
                                            <span className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors w-full" onClick={() => handleNavigation("/newsale")}>
                                                <i className="pi pi-money-bill mr-2"></i>
                                                <span className="font-medium">Продаж</span>
                                                <Ripple />
                                            </span>
                                        </li>}
                                        <li>
                                            <span className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors w-full" onClick={() => handleNavigation("/opensales")}>
                                                <i className="pi pi-clipboard mr-2"></i>
                                                <span className="font-medium">Незакриті замовлення</span>
                                                <Ripple />
                                            </span>
                                        </li>
                                        <li>
                                            <span className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors w-full" onClick={() => handleNavigation("/salehistory")}>
                                                <i className="pi pi-cart-plus mr-2"></i>
                                                <span className="font-medium">Історія</span>
                                                <Ripple />
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-auto">
                                    <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
                                    <span className="m-3 flex align-items-center cursor-pointer p-3 gap-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" />
                                        <span className="font-bold">Галя Балувана</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            ></Sidebar>
            <div className='grid'>
                <div className='col-12 p-0'>
                    <div className='card'>
                        <Toolbar start={startContent} center={centerContent} end={endContent} />
                    </div>
                </div>
            </div>
        </>
    );
}
}

export default Layout;