import axios from "axios";
import Cookies from "js-cookie";
import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(Cookies.get("authToken") || "");
    const navigate = useNavigate();

    const loginAction = async (data) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}auth/token/`, JSON.stringify(data), {
            headers: {
              'Content-Type': 'application/json',
              'X-APP-ID': 'CASH_APP'
            }
          });
        const parsedResponse = response.data;

        console.log(parsedResponse);
        if (parsedResponse) {
          setToken(parsedResponse.access);
          Cookies.set("authToken", parsedResponse.access);
          Cookies.set("refreshToken", parsedResponse.refresh);
          navigate("/shift");
          return;
        }
        throw new Error(parsedResponse.message);
      } catch (parsedResponse) {
         return parsedResponse.response.status;
      }
    };
  
    const logOut = () => {
      setUser(null);
      setToken("");
      localStorage.removeItem("authToken");
      navigate("/login");
    };
  
    return (
      <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
        {children}
      </AuthContext.Provider>
    );
  
  };

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};