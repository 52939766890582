const paymentTypeTranslations = {
    "CASH": "Готівка",
    "CARD": "Термінал",
    "IBAN": "Рахунок"
}

const transactionTypes = {
    "SALE": "Продаж",
    "CASH_DEPOSIT": "Внесення готівки",
    "CASH_WITHDRAW": "Отримання готівки",
    "PAYMENT": "Оплата"
}

export const TranslatePaymentType = (name) => {
    return paymentTypeTranslations[name];
}

export const TranslateTransType = (name) => {
    return transactionTypes[name];
}