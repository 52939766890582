export const formatCurrency = (value, currency) => {
    const locale = currency === "UAH" ? "uk-UA" : 'en-US';
    const numericValue = Number(value).toFixed(2);

    // Format the number as currency
    return new Intl.NumberFormat(locale, { 
        style: 'currency', 
        currency: currency 
    }).format(numericValue);
};

export const transformDate = (date) => {
    const day = ('0' + date.getDate()).slice(-2); // Add leading zero if needed
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month is zero-based, so add 1
    const year = date.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate
}

export const formatDate = (unformattedDate) => {
    const date = new Date(unformattedDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
}

export const formatShortDate = (unformattedDate) => {
    const date = new Date(unformattedDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}